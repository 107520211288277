import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getBackendURL } from '@/constants/api';
import { UserAccount, UserSettings } from '@/interfaces';

const TOKEN_STORAGE_KEY = 'VFQLMS.token';

export const baseApiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: getBackendURL(),
    prepareHeaders: (headers) => {
      headers.set('Content-type', 'application/json');
      headers.set('Authorization', `Bearer ${getToken()}`);
      return headers;
    },
  }),
  tagTypes: ['UserList', 'PaginatedUsers', 'ClientList'],
  endpoints: (builder) => ({
    getUserList: builder.query<UserAccount[], { enterpriseOnly?: boolean }>({
      query: ({ enterpriseOnly }) => 'user/get-users?enterpriseOnly=' + (enterpriseOnly ?? false),
      transformResponse: (response: { data: UserAccount[] }) => response.data,
      providesTags: ['UserList'],
    }),
    getPaginatedUserList: builder.query<UserAccount[], { page: number; limit: number; search: string }>({
      query: ({ page, limit, search }) => `user/get-paginated-users?page=${page}&limit=${limit}&search=${search}`,
      transformResponse: (response: { data: UserAccount[] }) => response.data,
      providesTags: ['PaginatedUsers'],
    }),
    createUser: builder.mutation<
      UserAccount,
      Pick<UserAccount, 'email' | 'name' | 'surname' | 'clientName' | 'clientId' | 'role'> & {
        checklist: string;
        settings: UserSettings;
      }
    >({
      query(body) {
        return {
          url: 'user/create-user',
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: { data: UserAccount }) => response.data,
      invalidatesTags: ['PaginatedUsers', 'UserList'],
    }),
    editUser: builder.mutation<
      UserAccount,
      Pick<UserAccount, 'id' | 'email' | 'name' | 'surname' | 'role' | 'customUserFields'>
    >({
      query(body) {
        return {
          url: 'user/edit-user',
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: { data: UserAccount }) => response.data,
      invalidatesTags: ['PaginatedUsers', 'UserList', 'ClientList'],
    }),
    updateUserRole: builder.mutation<UserAccount, { id: string; role: string; previousRole: string }>({
      query(body) {
        return {
          url: 'user/change-user-role',
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: { data: UserAccount }) => response.data,
      async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
        const postResult = dispatch(
          baseApiSlice.util.updateQueryData('getUserList', {}, (userList) => {
            const index = userList.findIndex((user) => user.id === updateArgs.id);
            if (index > -1) {
              const currentUser = userList[index];
              Object.assign(userList[index], {
                ...currentUser,
                ...updateArgs,
              });
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          postResult.undo();
        }
      },
      invalidatesTags: ['PaginatedUsers', 'UserList', 'ClientList'],
    }),
    updateLastActiveDate: builder.mutation<void, void>({
      query(body) {
        return {
          url: 'user/update-last-active-date',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['PaginatedUsers', 'UserList'],
    }),
  }),
  refetchOnMountOrArgChange: true,
});

function getToken(): string {
  const sessionToken = window.sessionStorage.getItem(TOKEN_STORAGE_KEY);
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);
  return sessionToken ?? token ?? '';
}

export const {
  useGetUserListQuery,
  useGetPaginatedUserListQuery,
  useCreateUserMutation,
  useEditUserMutation,
  useUpdateUserRoleMutation,
  useUpdateLastActiveDateMutation,
} = baseApiSlice;
