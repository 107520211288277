import { PaymentMethod } from '@stripe/stripe-js';

export enum UserSubscriptionType {
  Free = 'free',
  Professional = 'professional',
}

export enum UserSubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Paused = 'paused',
  Unpaid = 'unpaid',
  Trial = 'trial',
}

export type UserSubscriptionCard = {
  cardId: string;
  last4: string;
  expirationDate: string;
};

export type UserSubscriptionInvoice = {
  id: string;
  date: Date;
  amount: number;
  status: string;
  pdf: string;
};

export type UserSubscription = {
  customerId: string;
  subscriptionId?: string;
  type: UserSubscriptionType;
  status: UserSubscriptionStatus;
  trialEnd?: Date;
  cancelAt?: Date;
  hasTrialed?: boolean;
  paymentMethod?: string | PaymentMethod;
  card?: UserSubscriptionCard;
  invoices?: UserSubscriptionInvoice[];
};
