import { Book, Insight, Technique, Tool } from '@/interfaces';

export enum CategoryColors {
  BLUE = 'blue',
  ORANGE = 'orange',
  RED = 'red',
  PURPLE = 'purple',
  GRAY = 'gray',
}

export enum ModelType {
  Book = 'book',
  Chapter = 'chapter',
  Course = 'course',
  Insight = 'insight',
  Pathway = 'pathway',
  Technique = 'technique',
  Tool = 'tool',
  Courses_Pathways = 'courses-pathways',
}

export enum HeaderImageSize {
  Cover = 'cover',
  Contain = 'contain',
}

export type UserFavoritesType = {
  techniques: Technique[];
  tools: Tool[];
  books: Book[];
  insights: Insight[];
};

export const EMPTY_USER_FAVORITES: UserFavoritesType = {
  techniques: [],
  tools: [],
  books: [],
  insights: [],
};

export type UserInterestsRelatedContent = UserFavoritesType & {
  userInterests: string[];
};

export const EMPTY_USER_RELATED_CONTENT: UserInterestsRelatedContent = {
  ...EMPTY_USER_FAVORITES,
  userInterests: [],
};
